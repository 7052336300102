<template>
  <Tab :list="tabList" :current="2" />
  <!-- <js-seamless-scroll :datas="list.data" class="flex-1">
    <div>
      <Item v-for="(item, index) in list.data" :item="item" :key="index" />
    </div>
  </js-seamless-scroll> -->
  <div class="fff flex-1 flex-col">
    <div class="flex-1">
        <Item v-for="(item, index) in list" :item="item" :key="index" />
    </div>
    <div v-if="allData.length <= 0" class="noData">暂无数据</div>
    <van-pagination 
      v-model="currentPage" 
      mode="simple"
      @change="handleCurrentChange"
      :total-items="allData.length"
      items-per-page='4'
      v-if="allData.length > 0"
    >
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
    </van-pagination>
  </div>
</template>

<script>
import { traceabilityTabList } from '@/utils/tab'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import { getPublicity } from '@/api/traceability'
import Item from './component/item.vue'
// import { jsSeamlessScroll } from 'vue3-seamless-scroll'

export default {
  components: {
    Tab,
    Item,
    // jsSeamlessScroll,
  },
  setup() {
    const tabList = reactive(traceabilityTabList)

    let list = ref([])
    let allData = ref([])
    let currentPage = ref(1)
    // 获取数据
    const init = () => {
      getPublicity().then((res) => {
        allData.value = res.result
        handleCurrentChange(1)
      })
    }
    init()
    function paginate(array, page_size, page_number) {
      return allData.value.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const handleCurrentChange = (val) => {
      list.value = paginate(list, 4, val);
    }

    return {
      tabList,
      list,
      currentPage,
      allData,
      handleCurrentChange
    }
  },
}
</script>

<style lang="less" scoped>
.flex-1 {
  overflow: hidden;
}
</style>
