<template>
  <div class="item">
    <img :src="item.sampleSourceAcid" @click="() => showImg(item.sampleSourceAcid)" />
    <van-row class="flex-1">
      <van-col class="col" span="24">
        <span class="label">经营户</span>
        <span class="value">{{ item.dealerUser }}</span>
      </van-col>
      <van-col class="col" span="12">
        <span class="label">摊位号</span>
        <span class="value">{{ item.stallName }}</span>
      </van-col>
      <van-col class="col" span="12">
        <span class="label">信用评分</span>
        <span class="value">{{ item.creditRank ? item.creditRank + '分' : '-' }}</span>
      </van-col>
      <van-col class="col" span="12">
        <span class="label">经营项目</span>
        <span class="value">{{ item.stockType }}</span>
      </van-col>
      <van-col class="col" span="24">
        <span class="label">上传时间</span>
        <span class="value">{{ item.sampleCreate }}</span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  setup() {
    const showImg = (img) => {
      ImagePreview([img])
    }
    return {
      showImg,
    }
  },
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  padding: 12px;
  background: #fff;
  // margin: 0 0 10px;
  font-size: 12px;
  img {
    width: 25%;
  }
}
.col {
  display: flex;
  color: #666;
  .label {
    color: #333;
    width: 90px;
    text-align: right;
    &::after {
      content: '：';
    }
  }
  .value {
    flex: 1;
  }
}
</style>
